.sub-title {
    color: #888;
    font-size: 14px;
    padding: 15px 0 9px 15px;
}

.no-reports {
    color: #0dee32;
    font-size: 12px;
    padding: 15px 0 9px 15px;
}

.not-square-grid .am-grid-icon {
    width: 40px;
    height: 60px;
}
